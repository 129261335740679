import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./header.css";

const Header = ({ showHomeButton }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const navigate = useNavigate();

  useEffect(() => {
    const closeMenu = (event) => {
      if (
        isMenuOpen &&
        !event.target.closest(".dropdownMenu") &&
        !event.target.closest(".hamburgerButton")
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", closeMenu);

    return () => document.removeEventListener("click", closeMenu);
  }, [isMenuOpen]);

  return (
    <header className="headerContainer">
      <div className="buttons">
        {showHomeButton && (
          <button className="homeButton" onClick={() => navigate("/")}>
            Home
          </button>
        )}
        <div className="menuContainer">
          <button
            className={`hamburgerButton ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="dropdownMenu">
          <button onClick={() => navigate("/history")}>History</button>
          <button onClick={() => navigate("/merch")}>Merch</button>
          <button onClick={() => navigate("/on-tour")}>On tour</button>
          <button onClick={() => navigate("/booking")}>Booking</button>
        </div>
      )}
    </header>
  );
};

export default Header;

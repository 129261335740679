// Home.js
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import classNames from "classnames";
import "./home.css";
import logo from "./logo.svg";

function Home() {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/our-story");
  }

  function adjustHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  // Debounce function to limit the rate at which a function is executed
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  // Optimized resize event listener
  window.addEventListener("load", adjustHeight);
  window.addEventListener("resize", debounce(adjustHeight, 250));

  return (
    <div className={classNames("homeContainer", "pagePadding")}>
      <Header />
      <main className="homeMain">
        <img src={logo} className="App-logo" alt="logo" />
        <button className="homePageButton" onClick={handleClick}>
          Enter
        </button>
      </main>
    </div>
  );
}

export default Home;

// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import OurStory from "./pages/ourStory/ourStory";
import "./App.css";
import UnderConstruction from "./pages/underConstruction/underConstruction";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-story" element={<OurStory />} />
        <Route
          path="/booking"
          element={<UnderConstruction pageName="Booking" />}
        />
        <Route path="/history" element={<OurStory />} />
        <Route
          path="/on-tour"
          element={<UnderConstruction pageName="On tour" />}
        />
        <Route path="/merch" element={<UnderConstruction pageName="Merch" />} />
      </Routes>
    </Router>
  );
}

export default App;

// OurStory.js
import React from "react";

import Header from "../../components/header";
import classNames from "classnames";
import "./ourStory.css";

function OurStory() {
  return (
    <div className={classNames("storyContainer", "pagePadding")}>
      <Header showHomeButton />
      <main className="storyMain">
        <h1>Our story</h1>
        <p>
          Cues of Danger is a Norwegian rock duo formed by Joachim and
          Stein-Erik, both born in 1988. But how they came to be is shrouded in
          mystery, with some whispering tales of their descent from the very
          rock gods themselves. Whether or not the rumors are true, one thing is
          certain: Joachim and Stein-Erik have been best friends since
          childhood, and their lifelong bond has only grown stronger as they've
          navigated the ups and downs of the music industry together.
        </p>
        <p>
          With a shared passion for music and a mutual love for the mighty
          beard, they've come together to create a unique sound that's equal
          parts raw energy and melodic depth. As veterans of the music scene,
          having previously played in bands like Airhosters and Burning
          Coalition, they bring a wealth of experience and expertise to their
          new project. With Cues of Danger, they're looking to push the
          boundaries of rock music, blending their influences to create a sound
          that's both familiar and innovative.
        </p>
        <p>
          Join the ride with Joachim and Stein-Erik as they bring their special
          brand of rock 'n' roll to the world, one mighty beard at a time.
        </p>
      </main>
    </div>
  );
}

export default OurStory;

import React from "react";

import Header from "../../components/header";
import classNames from "classnames";
import "./underConstruction.css";

function UnderConstruction({ pageName }) {
  return (
    <div className={classNames("constructionContainer", "pagePadding")}>
      <Header showHomeButton />
      <main className="constructionMain">
        <h1>{pageName} page is currently under construction</h1>
      </main>
    </div>
  );
}

export default UnderConstruction;
